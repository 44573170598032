import * as React from "react"
import { graphql } from "gatsby"
import CmsPageContent from "../components/cms/CmsPageContent"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import PropTypes from "prop-types"
import YouTube from "../components/common/YouTube"

const CmsPage = ({ data }) => {
  let page = data.page
  const slug = (data.page.blog === true ? "blog/" : "") + data.page.slug
  return (
    <Layout
      breadcrumbs={true}
      canonical={page.canonical_url || slug}
      title={page.title}
      className=""
    >
      <Seo
        title={page.meta_title}
        canonical={page.canonical_url || slug}
        description={page.meta_description}
        article={page}
        noindex={page.noindex === true}
      />
      <div className="flex flex-col">
        <CmsPageContent page={page} />
      </div>
    </Layout>
  )
}
CmsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query CmsPageQuery($pageId: String!) {
    page: strapiPage(id: { eq: $pageId }) {
      title
      blog
      html
      meta_title
      meta_description
      noindex
      slug
      canonical_url
      hero_image_url
      created_at
      updated_at
      updated_at_formatted: updated_at(formatString: "MMMM Do, YYYY")
      blocks {
        id
        heading
        block
        videos {
          title
          url
          id
        }
        description
        strapi_component
        categories {
          parent
          prioroty
          gallery {
            url
            image
            name
            totalCount
          }
          category {
            name
            description
            bottom_description
            h1_header
            helper
          }
        }
      }
    }
  }
`

// blocks {
//   __typename
//   categories {
//     __typename
//     gallery {
//       name
//       image
//       url
//     }
//     category {
//       name
//     }
//   }
//   heading
// }

export default CmsPage
